<div class="container mt-5 d-block">
    <div class="row m-5">
        <div class="col-md">
            <img class="img-fluid" src="{{assetsDirectoryPath}}/GrupoGiacoLogo.png" alt="Logo grupo Giacomini" height="240" width="290">
        </div>
        <div class="col-md">
            <p class="down-side-image" style="text-align: justify; font-size: 20px;"> O <b>Grupo Giacomini</b> surgiu no ramo da construção civil com um olhar voltado à responsabilidade social, por meio da segurança e do serviço de qualidade prestado aos clientes. Nesse cenário, busca-se a inovação, a sustentabilidade, a elegância
                e uma nova proposta arquitetônica, a fim de garantir qualidade de vida aos moradores de suas edificações. </p>
        </div>
    </div>
    <div class="row m-5">
        <div class="col-md">
            <img class="img-fluid" src="{{assetsDirectoryPath}}/ResidencialLogo.png" alt="Logo grupo Giacomini" height="240" width="290">
        </div>
        <div class="col-md">
            <p class="down-side-image" style="text-align: justify; font-size: 20px;"> O <b>Residencial Giacomini</b> é um projeto que nasceu dessa proposta, tendo como escopo a transparência, a excelência e o comprometimento com o bem-estar dos clientes. O prédio traz um design inovador, uma distribuição inteligente dos cômodos,
                um acabamento de alto padrão, uma localização privilegiada, inúmeros diferenciais, além do contato com a natureza e com a tranquilidade local. </p>
        </div>
    </div>
</div>