/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./contact.component";
var styles_ContactComponent = [i0.styles];
var RenderType_ContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
export function View_ContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "m-5"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons iconDesign mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" emoji_people "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fale com o Grupo Giacomini!"]))], null, null); }
export function View_ContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i1.ɵdid(1, 114688, null, 0, i2.ContactComponent, [i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactComponentNgFactory = i1.ɵccf("app-contact", i2.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
