import { Component, OnInit, Injector } from '@angular/core';
import { IImage } from "../Models/IImage.interface";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  // public imageUrlArray: (string | IImage)[] = [];
  public img_str: String = '';
  public assetsDirectoryPath: String;

  constructor(public injector: Injector) {
    this.assetsDirectoryPath = this.injector.get('assetsDirectoryPath');
  }

  ngOnInit() {
    if (window.innerWidth > 768) {
      // this.imageUrlArray = [
      //   { url: this.assetsDirectoryPath+"/homeBanner.jpg" }
      // ]
      this.img_str = this.assetsDirectoryPath + "/homeBanner.jpg";
    } else {
      // this.imageUrlArray = [
      //   { url: this.assetsDirectoryPath+"/publ_giacomini.jpeg" }
      // ]
      this.img_str = this.assetsDirectoryPath + "/publ_giacomini.jpeg";
    }
  }

  onResize(event) {
    console.log(event.target.innerWidth);
    if (event.target.innerWidth > 768) {
      // this.imageUrlArray = [
      //   { url: this.assetsDirectoryPath+"/homeBanner.jpg" }
      // ]
      this.img_str = this.assetsDirectoryPath + "/homeBanner.jpg";
    } else {
      // this.imageUrlArray = [
      //   { url: this.assetsDirectoryPath+"/publ_giacomini.jpeg" }
      // ]
      this.img_str = this.assetsDirectoryPath + "/publ_giacomini.jpeg";
    }
  }

}