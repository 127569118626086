import { Component, OnInit, Injector } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public assetsDirectoryPath: String;

  constructor(public injector: Injector){
    this.assetsDirectoryPath = this.injector.get('assetsDirectoryPath');
  }

  ngOnInit() {
  }

}
