import { Component, OnInit, Injector } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  public assetsDirectoryPath: String;

  constructor(public injector: Injector){
    this.assetsDirectoryPath = this.injector.get('assetsDirectoryPath');
  }

  ngOnInit() {
  }

}
