<!-- <div id="preNavBar-Container" class="sticky-top box-shadow" style="background-color: #393333; height: 30px;"></div> -->
<div class="d-flex flex-column flex-md-row align-items-center px-md-4 box-shadow" style="background-color: #952323; box-shadow: 0px 3px 3px #393333;">
    <div (click)="goHome()" id="logoCameUp" class="my-0 mr-md-auto">
        <img class="primum_logo" src="{{assetsDirectoryPath}}/GiacominiLogoOnly2.png">
        <span class="giacominiText text-dark" style="margin-right: 10px;"><b> Grupo Giacomini </b></span>
    </div>
    <nav class="my-2 my-md-0 mr-md-3 text-nav-bar">
        <a class="homeHide p-2 text-white" routerLink="/home">Home</a>
        <a class="p-2 text-white" routerLink="/about">Sobre</a>
        <a class="p-2 text-white" routerLink="/enterprise"><b>Residencial Giacomini</b></a>
        <a class="p-2 text-white" routerLink="/contact">Contato</a>
    </nav>
</div>

<main style="overflow-y: hidden;">
    <!-- style="height: 64vh;" -->
    <router-outlet></router-outlet>
</main>

<div class="container">
    <footer class="pt-4 my-md-3 pt-md-5 border-top" style="text-align: center;">
        <div class="row">
            <div class="col-12 col-md">
                <img class="mb-2" src="{{assetsDirectoryPath}}/GrupoGiacoLogo.png" alt="" width="150" height="120">
            </div>
            <div class="col-12 col-md">
                <h5 class="col-12 col-md">Contate nossa equipe!</h5>
                <div class="col-12 col-md">
                    <h6>Email</h6>
                    <ul class="list-unstyled text-small">
                        <li>
                            <p class="text-dark">grupogiacomini.emp@gmail.com</p>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md">
                    <h6>Telefone</h6>
                    <ul class="list-unstyled text-small">
                        <li>
                            <p class="text-dark">(47) 98417-7330</p>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md">
                    <h6>Whatsapp</h6>
                    <a target="_blank" href="https://web.whatsapp.com/send?phone=5547984177330" class="btn btn-outline-success">
                        <img src="{{assetsDirectoryPath}}/WhatsApp-icon.png" width="25px" height="25px"> Agende sua visita!
                    </a>
                </div>
            </div>
            <div class="col-12 col-md" style="display: flow-root;">
                <h5 class="col-12 col-md">Redes sociais!</h5>
                <div class="col-12 col-md">
                    <img src="{{assetsDirectoryPath}}/instagram-logo.png" height="25px" width="25px">
                    <p class="text-dark"><a href="https://www.instagram.com/grupogiacomini/" target="_blank">Siga-nos no Instagram!</a></p>
                </div>
                <div class="col-12 col-md">
                    <img src="{{assetsDirectoryPath}}/facebook-logo-2-1.png" height="25px" width="25px">
                    <p class="text-dark"><a href="https://www.facebook.com/Grupo-Giacomini-106732804207671" target="_blank">Siga-nos no Facebook!</a></p>
                </div>
            </div>
            <div class="col-12 col-md" style="display: flow-root;">
                <div class="col-12 w-100">
                    <h1 style="text-align: center;"><img class="m-auto" src="https://patrikfreisner.github.io/primum_mainpage/LOGO_PRIMUM_BLACK.png" alt="" width="24" height="24"></h1>
                </div>
                <div class="col-12 col-md w-100">
                    <small style="margin-right: 5px;"><b> Desenvolvido por <a href="http://www.primumtec.com/?redirectFrom=giacomini" target="_blank"><span>Primum!</span></a></b></small>
                </div>
                <div class="col-12 col-md">
                    <small class="mb-3 text-muted">© 2020-{{currentYear}}</small>
                </div>
            </div>
        </div>
    </footer>
</div>