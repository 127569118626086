import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  ngOnInit() { }
  title = 'Grupo Giacomini';
  currentYear = (new Date()).getFullYear();

  public assetsDirectoryPath: String;

  constructor(
    public injector: Injector,
    private router: Router
  ) {
    this.assetsDirectoryPath = this.injector.get('assetsDirectoryPath');
  }

  goHome() {
    this.router.navigateByUrl('/home');
  }

  redirectToExternalPage(linkToPage) {
    window.document.location.href = linkToPage;
  }
}
