import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SlideshowModule } from 'ng-simple-slideshow';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { ContactComponent } from './contact/contact.component';

//// Change It to "./assets" before compiling to Github Pages; ////
//const assetsDirectoryPath = '../assets'; // Development Config
const assetsDirectoryPath = './assets'; // GitHub Pages Config

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    EnterpriseComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlideshowModule
  ],
  providers: [
    { provide: 'assetsDirectoryPath', useValue: assetsDirectoryPath }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
