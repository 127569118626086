<hr width="70%">
<div class="container d-block mt-5 mb-5" style="text-align: center;">
    <div id="IconArea" class="m-3 d-block">
        <i class="material-icons iconDesign">
            people_alt
        </i>
        <br>
        <h2 class="m-3"> Área comum </h2>
    </div>
    <div id="TextArea" class="m-3">
        <div class="TextContainer" style="font-size: 20px; text-align: center;">
            <p>Elevador com capacidade para 8 pessoas (Marca Otis)</p>
            <p>Corredores com porcelanato alto padrão</p>
            <p>2 áreas de festa com 110m²</p>
            <p>Área fitness com 90m²</p>
            <p>Hall decorado</p>
        </div>
    </div>
</div>
<hr width="70%">
<div class="container d-block mt-5 mb-5" style="text-align: center;">
    <div id="IconArea" class="m-3">
        <i class="material-icons iconDesign">
            emoji_food_beverage
        </i>
        <h2 class="m-3"> Área privativa </h2>
    </div>
    <div id="TextArea" class="m-3 d-block">
        <div class="TextContainer" style="font-size: 20px; text-align: center;">
            <p>Vagas de garagem cobertas (os primeiros compradores terão a possiblidade de adquirir 1 vaga adicional cada)</p>
            <p>Banheiros, cozinha e lavanderia com revestimento até o teto (Alto padrão)</p>
            <p>Área gourmet e sacada de serviços em todos os apartamentos</p>
            <p>Acabamento em gesso rebaixado em todos os apartamentos</p>
            <p>Pré-instalação para ar condicionado e ampla laje técnica</p>
            <p>Chão com porcelanato 60x60 (Alto padrão)</p>
            <p>2 banheiros por apartamento</p>
            <p>Paredes com massa corrida</p>
            <p>Plantas de 73m² e de 84m²</p>
            <p>Luzes de led na sala</p>
        </div>
        <div class="d-flex mt-5 row">
            <div class="m-auto col-md" style="text-align: center;">
                <h4> Apartamento 73m²</h4>
                <h4> 1 suíte + 1 dormitório</h4>
                <div (mouseenter)="onHover('small',true)" (mouseleave)="onHover('small',false)">
                    <button [class.btn-active]="btnActiveSmall" type="button" style="position: absolute; float: right;" class="btn btn-outline-light" data-toggle="modal" data-target="#ApSmallModal">
                        <i class="material-icons">
                            open_in_new
                            </i>
                      </button>
                    <img class="border img-fluid" src="{{assetsDirectoryPath}}/planta2.jpeg" height="450" width="358.38px">
                </div>
            </div>
            <div class="m-auto col-md" style="text-align: center;">
                <h4 class="down-side-image"> Apartamento 84m²</h4>
                <h4> 1 suíte + 2 dormitórios</h4>
                <div (mouseenter)="onHover('large',true)" (mouseleave)="onHover('large',false)">
                    <button [class.btn-active]="btnActiveLarge" type="button" style="position: absolute; float: right;" class="btn btn-outline-light" data-toggle="modal" data-target="#ApLargeModal">
                        <i class="material-icons">
                            open_in_new
                            </i>
                      </button>
                    <img class="border img-fluid" src="{{assetsDirectoryPath}}/planta1.jpeg" height="450" width="358.38px">
                </div>
            </div>
        </div>
    </div>
</div>
<hr width="70%">
<div class="container d-block mt-5 mb-5" style="text-align: center;">
    <div id="IconArea" class="m-3">
        <i class="material-icons iconDesign">
            beenhere
        </i>
        <h2 class="m-3"> Localização privilegiada </h2>
    </div>
    <div id="TextArea" class="m-3 d-block">
        <div class="TextContainer" style="font-size: 20px; text-align: center;">
            <p><b>Prédio próximo a:</b></p>
            <p>Instituições de ensino</p>
            <p>Posto de combustível</p>
            <p>Grandes empresas</p>
            <p>Linha de ônibus</p>
            <p>Supermercados</p>
            <p>Restaurantes</p>
            <p>Farmácia</p>
            <p>Bancos</p>

        </div>
        <div class="border mt-5" style="text-align: center; width: 100%;">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2332.5798728887507!2d-49.03791566118203!3d-26.47345046459737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94debfd9be36f0eb%3A0x832884c4d021be8e!2sR.%20Richard%20Bublitz%20-%20Vieira%2C%20Jaragu%C3%A1%20do%20Sul%20-%20SC!5e0!3m2!1spt-BR!2sbr!4v1579822218721!5m2!1spt-BR!2sbr"
                height="450" width="100%" frameborder="0" style="border:0;" allowfullscreen="true"></iframe>
            <!-- <img class="img-fluid" src="../../assets/projeto_img2.jpeg" height="450" width="358.38px"> -->
        </div>
    </div>
</div>

<!-- Modal -->
<div id="ApSmallModal" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ApSmallModalLabel">Apartamento 73m² | 1 suíte + 1 dormitório</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div style="text-align: center;">
                    <img class="border img-fluid" src="{{assetsDirectoryPath}}/planta2.jpeg">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div id="ApLargeModal" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ApSmallModalLabel">Apartamento 84m² | 1 suíte + 2 dormitórios</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div style="text-align: center;">
                    <img class="border img-fluid" src="{{assetsDirectoryPath}}/planta1.jpeg">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>