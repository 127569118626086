import { Component, OnInit, Injector } from '@angular/core';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.css']
})
export class EnterpriseComponent implements OnInit {

  public btnActiveSmall = false;
  public btnActiveLarge = false;
  public assetsDirectoryPath: String;

  constructor(public injector: Injector){
    this.assetsDirectoryPath = this.injector.get('assetsDirectoryPath');
  }

  ngOnInit() {
  }

  onHover(sizeSelector, isActive) {
    if (sizeSelector == 'small') {
      this.btnActiveSmall = isActive;
    } else {
      this.btnActiveLarge = isActive;
    }
  }

}
